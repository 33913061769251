import React from "react";
import { Route, Switch } from "react-router-dom";
import styled from "styled-components";
import Home from "./Home";
import AndroidStartPage from "./AndroidStartPage";

const Container = styled.div`
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-text-stroke:1px transparent;
  -moz-osx-font-smoothing: grayscale;
`;

const App = () => (
  <Container>
    <Switch>
      <Route
        exact
        path="/"
        component={Home}
      />
      <Route path="/start" component={AndroidStartPage} />
    </Switch>
  </Container>
);

export default App;
