import React, { useContext } from "react";
import styled from "styled-components";
import closeButton from "../images/close_button.png";
import { AnalyticsContext } from "../Analytics";

const Image = styled.img`
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    border: 0px none;
    position: absolute;
    z-index: 100;
    top: 0.8rem;
    right: 0.8rem;
    padding: 0;
    cursor: pointer;
`;

const Button = (props) => {
  const analytics = useContext(AnalyticsContext);
  return (
    <Image
      src={closeButton}
      alt="close"
      onClick={(e) => {
        analytics.logEvent("map_close_enlarged_map");
        return props.onClick(e);
      }}
    />
  );
};

export default Button;
