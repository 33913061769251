import React from "react";
import styled from "styled-components";
import ButtonLink from "./components/ButtonLink";
import onboarding from "./images/onboarding.png";

const Container = styled.div`
  font-family: "Noto Sans JP";
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h1`
  font-size: 20px;
  text-align: center;
  color: #03AFDB;

  line-height: 30px;
  height: 30px;
  margin: 1rem 0;
  font-weight: 800;
`;

const Description = styled.div`
  font-size: 16px;
  color: #03AFDB;
  text-align: center;
  line-height: 21px;
  padding: 1rem;
  font-weight: 800;
`;


const Image = styled.img`
    height: 264px;
    width: 264px;;
    border: 0px none;
    z-index: 100;
`;

const AndroidStartPage = () => (
  <Container>
    <Title>TenTen自販機マップ</Title>

    <Image src={onboarding} alt="onboarding" />

    <Description>
      近くのTenTen自販機で
      <br />
      おトクにドリンクを購入しよう！
    </Description>
    <div style={{ padding: "2rem" }}>
      <ButtonLink primary href="/">TenTen自販機マップを見る</ButtonLink>
    </div>
  </Container>
);

export default AndroidStartPage;
