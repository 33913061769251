import React from "react";
import firebase from "firebase/app";
import "firebase/analytics";

export const AnalyticsContext = React.createContext();

export const withAnalytics = WrappedComponent => class extends React.Component {
  constructor(props) {
    super(props);
    this.state = { analytics: null };
  }

  componentDidMount() {
    let firebaseConfig;
    if (window.location.href.includes("localhost")
      && window.location.href.includes("staging")) {
      firebaseConfig = {
        apiKey: "AIzaSyBuZwaUsZ1VufeQiNTZ-4VXdh0GzENCiT8",
        authDomain: "tenten-web-app.firebaseapp.com",
        projectId: "tenten-web-app",
        storageBucket: "tenten-web-app.appspot.com",
        messagingSenderId: "569323126148",
        appId: "1:569323126148:web:026a3113269c6ecb20b1d1",
        measurementId: "G-12NSCH35SN",
      };
    } else {
      firebaseConfig = {
        apiKey: "AIzaSyCewpzYOppVeXYYsgGrsDA138cKhXPuJ-w",
        authDomain: "tenten-web-app-prod.firebaseapp.com",
        projectId: "tenten-web-app-prod",
        storageBucket: "tenten-web-app-prod.appspot.com",
        messagingSenderId: "249075562546",
        appId: "1:249075562546:web:195cae3404fbfc58a5a42b",
        measurementId: "G-5VHKGL5G2N",
      };
    }

    // Initialize Firebase
    const app = firebase.initializeApp(firebaseConfig);
    const analytics = firebase.analytics(app);

    this.setState({
      analytics,
    });
  }

  render() {
    return (
      <AnalyticsContext.Provider value={this.state.analytics}>
        <WrappedComponent />
      </AnalyticsContext.Provider>
    );
  }
};
