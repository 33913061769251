import React from "react";
import styled from "styled-components";
import ButtonLink from "./ButtonLink";

const Modal = styled.div`
  z-index: 1;
  overflow: auto;
  background-color: white;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 1rem;

  position: absolute;
  bottom: 0px;
  width: 100%;

  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 15px 15px 0 0;
  box-sizing: border-box;
`;

const Card = styled.div`
  display: flex;
  flex-direction: row;
`;


const Image = styled.img`
  width: 60px;
  height: 64px;
  margin-right: 1rem;
  object-fit: contain;
  border: 1px #cacaca solid;
  padding: 0px 4px;
`;

const InfoModal = ({ selectedPlace, clusterCount }) => {
  if (!selectedPlace) {
    return (<span />);
  }

  const merchantId = selectedPlace.merchantId || "default";

  return (
    <Modal>
      <Card>
        <Image src={`static/assets/merchants/${merchantId}.png`} />
        <div>
          { clusterCount && clusterCount > 1 && (<div style={{ fontWeight: "bold", fontSize: 16 }}>{`対応可能自動販売機${clusterCount}台あります ` }</div>) }
          <div>{selectedPlace && selectedPlace.address}</div>
        </div>
      </Card>
      <ButtonLink
        primary
        style={{ alignSelf: "center", margin: "1rem auto" }}
        href={`http://maps.apple.com/?q=${selectedPlace.latitude},${selectedPlace.longitude}`}
      >
        自販機までのルート
      </ButtonLink>
    </Modal>
  );
};

export default InfoModal;
