import React from "react";
import { hydrate } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { withAnalytics } from "./Analytics";

const AppWithAnalytics = withAnalytics(App);
hydrate(
  <BrowserRouter>
    <AppWithAnalytics />
  </BrowserRouter>,
  document.getElementById("root"),
);

if (process.env.NODE_ENV === "development") {
  if (module.hot) {
    module.hot.accept();
  }
}
