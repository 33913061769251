import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import {
  Map, Marker,
} from "google-maps-react";
import { MarkerClusterer, GridAlgorithm } from "@googlemaps/markerclusterer";
import googleMapStyles from "./GoogelMapStyle";
import CenterButton from "./components/CenterButton";
import CloseButton from "./components/CloseButton";
import pinIcon from "./images/pin.svg";
import selectedPinIcon from "./images/selected_pin.svg";
import markerBlankIcon from "./images/pin_blank.svg";
import activeMarkerBlankIcon from "./images/pin_active_blank.svg";
import circleIcon from "./images/circle.svg";
import InfoModal from "./components/InfoModal";
import { AnalyticsContext } from "./Analytics";


const Container = styled.div`
  display: flex;
  justify-content: center;

  .gm-style-iw{
    background-color: #03AFDB;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 700;
    max-width: 200px;
    padding: 0.7rem !important;
    box-shadow: none !important;
  }

  .gm-style-iw-c button{
     visibility: hidden;
   }

  .gm-style-iw-d{
    overflow: hidden !important;
  }

  .gm-style-iw-t{
    &:after {
      box-shadow: none !important;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 19px 14px 0 14px;
      border-color:  #03AFDB transparent transparent transparent;
      transform: none !important;
      left: -14px;
      top: -8px;
    }
  }
`;

const MapStyled = styled(Map)`
 ${props => (props.fullscreen
    ? `
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed !important;
    top: 0;
    left: 0;
    box-sizing: border-box;
    `
    : `
    width: 50% !important;
    height: 568px !important;
    @media (max-width: 768px) {
      width: 90% !important;
      height: 340px !important;
    }
    `
  )}
`;


const isEmbed = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

export const getIcons = (google) => {
  const markerIcon = {
    url: pinIcon,
    anchor: new google.maps.Point(24, 48),
    scaledSize: new google.maps.Size(48, 48),
  };
  const activeMarkerIcon = {
    url: selectedPinIcon,
    anchor: new google.maps.Point(36, 72),
    scaledSize: new google.maps.Size(72, 72),
  };
  const clusterIcon = {
    url: markerBlankIcon,
    anchor: new google.maps.Point(24, 48),
    labelOrigin: new google.maps.Point(24, 22),
    scaledSize: new google.maps.Size(48, 48),
  };
  const activeClusterIcon = {
    url: activeMarkerBlankIcon,
    labelOrigin: new google.maps.Point(36, 32),
    anchor: new google.maps.Point(36, 72),
    scaledSize: new google.maps.Size(72, 72),
  };

  return {
    markerIcon,
    activeMarkerIcon,
    clusterIcon,
    activeClusterIcon,
  };
};


const MapContainer = ({
  google,
  initialCoordinates,
  vendingMachines,
  onCenter,
  fullscreen,
  setFullscreen,
  selectedPlace,
  activeMarker,
  markers,
  markerClusterer,
  setMarkers,
  setSelectedPlace,
  setActiveMarker,
  setMarkerClusterer,
  renderCluster,
}) => {
  const {
    markerIcon,
    activeMarkerIcon,
    clusterIcon,
    activeClusterIcon,
  } = getIcons(google);

  const [mapRef, setMapRef] = useState();
  const analytics = useContext(AnalyticsContext);
  const onMarkerClick = (props, marker) => {
    analytics.logEvent("map_view_location_detail", { value: props });

    setSelectedPlace(props);
    setActiveMarker(marker);
    mapRef.panTo(new google.maps.LatLng(props.latitude, props.longitude));
  };

  const onMapClicked = () => {
    if (markerClusterer) {
      markerClusterer.clusters.map(cluster => cluster.marker.setIcon(clusterIcon));
    }

    setActiveMarker(null);
    setSelectedPlace(null);
  };

  const mapLoaded = (mapProps, map) => {
    map.setOptions({
      styles: googleMapStyles,
    });
    setMapRef(map);
    const success = position => map.setCenter({
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    });
    onCenter(success);
  };

  useEffect(() => {
    const findMarker = (marker, vm) => (
      marker.position.lat() === vm.latitude && marker.position.lng() === vm.longitude);
    const newVendingMachines = vendingMachines.filter(
      vm => !markers.find(marker => findMarker(marker, vm)),
    );
    const newMarkers = newVendingMachines.map((location) => {
      const marker = new google.maps.Marker({
        position: { lat: location.latitude, lng: location.longitude },
        mapRef,
        icon: markerIcon,
      });
      marker.addListener("click", () => onMarkerClick(location, marker));
      return marker;
    });

    setMarkers(markers.concat(newMarkers));
    // eslint-disable-next-line  no-unused-vars
    const clusterer = new MarkerClusterer({
      map: mapRef,
      algorithm: new GridAlgorithm({
        maxZoom: 30,
      }),
      markers: newMarkers,
      onClusterClick: (event, cluster) => {
        cluster.marker.setIcon(activeClusterIcon);
        const firstMarker = cluster.markers[0];
        const vendingMachine = vendingMachines.find(
          vm => findMarker(firstMarker, vm),
        );
        const selectedMarker = newMarkers.find(
          marker => cluster.markers[0].position.lat() === marker.position.lat()
          && cluster.markers[0].position.lng() === marker.position.lng(),
        );

        setSelectedPlace(vendingMachine);
        setActiveMarker(selectedMarker);
        const center = new google.maps.LatLng(selectedMarker.position.lat(),
          selectedMarker.position.lng());
        mapRef.panTo(center);
      },
    });
    clusterer.renderer = { render: renderCluster };
    setMarkerClusterer(clusterer);
  }, [vendingMachines]);


  let clusterSize;
  if (markerClusterer && selectedPlace) {
    markerClusterer.clusters.map((cluster) => {
      const activeCluster = cluster.markers.find(
        marker => marker.position.lat() === selectedPlace.latitude
        && marker.position.lng() === selectedPlace.longitude,
      );
      if (activeCluster) {
        clusterSize = cluster.markers.length;
      }
      return activeCluster;
    });
  }

  markers.map(marker => marker.setIcon(markerIcon));
  if (activeMarker) {
    activeMarker.setIcon(activeMarkerIcon);
  }

  return (
    <Container>
      <MapStyled
        google={google}
        initialCenter={initialCoordinates}
        zoom={16}
        streetViewControl={false}
        mapTypeControl={false}
        gestureHandling="greedy"
        panControl
        fullscreenControl={false}
        zoomControl={false}
        fullscreen={fullscreen}
        onReady={(mapProps, map) => mapLoaded(mapProps, map)}
        onClick={onMapClicked}
      >
        { !isEmbed() && fullscreen && (<CloseButton onClick={() => setFullscreen(false)} />) }
        { !isEmbed() && (<CenterButton onCenter={onCenter} fullscreen={fullscreen} />) }
        <Marker
          clickable={false}
          position={initialCoordinates}
          icon={{
            url: circleIcon,
            anchor: new google.maps.Point(5, 5),
            scaledSize: new google.maps.Size(10, 10),
          }}
        />

        <InfoModal
          selectedPlace={selectedPlace}
          clusterCount={clusterSize}
        />

      </MapStyled>
    </Container>
  );
};

export default MapContainer;
