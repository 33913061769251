
import axios from "axios";

export const fetchVendingMachines = async () => {
  const response = await axios("/api/vending_machines");
  return response.data;
};

export default {
  fetchVendingMachines,
};
