import React, { useContext } from "react";
import styled from "styled-components";
import currentLocation from "../images/current_location.png";
import { AnalyticsContext } from "../Analytics";

const Image = styled.img`
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    border: 0px none;
    position: absolute;
    z-index: 100;
    top: ${({ fullscreen }) => (fullscreen ? "4.5rem" : "0.8rem")};
    right: 0.8rem;
    padding: 0;
    cursor: pointer;
`;

const Button = (props) => {
  const success = position => props.map.setCenter({
    lat: position.coords.latitude,
    lng: position.coords.longitude,
  });
  const analytics = useContext(AnalyticsContext);

  return (
    <Image
      src={currentLocation}
      alt="center GPS"
      fullscreen={props.fullscreen}
      onClick={() => {
        analytics.logEvent("map_center_map");
        return props.onCenter(success);
      }}
    />
  );
};

export default Button;
