import React, { useState, useContext } from "react";
import styled from "styled-components";
import MapController from "./MapController";
import Button from "./components/Button";
import ButtonLink from "./components/ButtonLink";
import { AnalyticsContext } from "./Analytics";

const MapContainer = styled.div`
  height: 568px;
  @media (max-width: 768px) {
    height: 340px;
  }
`;

const Container = styled.div`
  font-family: "Noto Sans JP";
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3rem;
`;

const Title = styled.h1`
  font-size: 20px;
  text-align: center;
  color: #03AFDB;

  line-height: 30px;
  height: 30px;
  margin: 1rem 0;
  font-weight: 800;
`;

const Subtitle = styled.h2`
  font-size: 16px;
  text-align: center;
  color: #03AFDB;
  margin-top: 1rem;
  margin-bottom: 0;
  font-weight: 800;
`;

const H3 = styled.h3`
  font-size: 14px;
  text-align: center;
  color: #03AFDB;
  margin-top: 1rem;
  margin-bottom: 0;
`;

const Description = styled.div`
  font-size: 14px;
  color: #494949;
  text-align: center;
  line-height: 21px;
  padding: 1rem;
`;

const Separator = styled.hr`
  border-top: 1px solid #D2D2D2;
  width: 50%;
  color: transparent;
  margin: 1rem;
  @media (max-width: 768px) {
    width: 80%;
  }
`;

const Spacing = styled.div`
  margin-top: 1rem;
`;

const Home = () => {
  const [fullscreen, setFullscreen] = useState(false);
  const analytics = useContext(AnalyticsContext);
  return (
    <Container>
      <Title>TenTen自販機マップ</Title>
      <MapContainer>
        <MapController
          fullscreen={fullscreen}
          setFullscreen={setFullscreen}
        />
      </MapContainer>
      <Subtitle>
        近くのTenTen自販機で
        <br />
        おトクにドリンクを購入しよう！
      </Subtitle>
      <Spacing />
      <Button
        primary
        onClick={() => {
          analytics.logEvent("map_enlarge_map");
          return setFullscreen(true);
        }}
      >
        大きな画面で見る
      </Button>
      <Description>
        ※TenTenアプリ対応自販機のうち、
        <br />
        公開可能な自販機のみを掲載。
        <br />
        情報は順次更新しています。

      </Description>

      <Separator />

      <Description>
        自販機が見つからない、
        <br />
        位置がズレているなどがありましたら、
        <br />
        こちらからご報告ください。
      </Description>

      <ButtonLink href="https://forms.gle/bhZkCsHcT5HEm1GP6" onClick={() => (analytics.logEvent("map_report"))}>報告する</ButtonLink>

      <Spacing />
      <Separator />

      <H3>TenTenアプリとは？</H3>

      <Description>
        「TenTenアプリ」対応マークのついた
        <br />
        自販機で使えるスタンプカードアプリです。
        <br />
        スタンプ15個でドリンクと交換できます。
      </Description>

      <ButtonLink href="https://www.asahiinryo.co.jp/company/vending_machine/app/" onClick={() => (analytics.logEvent("map_open_app_site"))}>TenTenアプリについて</ButtonLink>
    </Container>
  );
};

export default Home;
