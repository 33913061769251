import React from "react";
import {
  GoogleApiWrapper,
} from "google-maps-react";
import Map, { getIcons } from "./Map";
import { fetchVendingMachines } from "./services";

const options = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0,
};

const googleMapKey = "AIzaSyDEpjB4sgIX27QTXg-H8eEaBVZw8dD6Ziw";

class MapController extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      coordinates: {
        lat: 35.68143,
        lng: 139.76715,
      },
      vendingMachines: [],
      markers: [],
    };

    this.setSelectedPlace = this.setSelectedPlace.bind(this);
    this.setActiveMarker = this.setActiveMarker.bind(this);
    this.setMarkers = this.setMarkers.bind(this);
    this.setMarkerClusterer = this.setMarkerClusterer.bind(this);
    this.renderCluster = this.renderCluster.bind(this);
  }

  async componentDidMount() {
    const data = await fetchVendingMachines();
    const vendingMachines = data.filter((vm) => {
      if (vm.latitude && vm.latitude > -90 && vm.latitude < 90) {
        if (vm.longitude && vm.longitude > -180 && vm.longitude < 180) {
          return true;
        }
      }
      return false;
    });
    this.setState({
      vendingMachines,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedPlace, markerClusterer } = this.state;
    if (prevState.selectedPlace && selectedPlace) {
      if (prevState.selectedPlace.latitude !== this.state.selectedPlace.latitude
        && prevState.selectedPlace.longitude !== this.state.selectedPlace.longitude
      ) {
        const icons = getIcons(this.props.google);
        markerClusterer.clusters.map((cluster) => {
          const activeCluster = cluster.markers.find(
            marker => marker.position.lat() === selectedPlace.latitude
        && marker.position.lng() === selectedPlace.longitude,
          );
          if (activeCluster) {
            cluster.marker.setIcon(icons.activeClusterIcon);
          } else {
            cluster.marker.setIcon(icons.clusterIcon);
          }
          return activeCluster;
        });
      }
    }
  }

  setSelectedPlace(selectedPlace) {
    this.setState({ selectedPlace });
  }

  setActiveMarker(activeMarker) {
    this.setState({ activeMarker });
  }

  setMarkers(markers) {
    this.setState({ markers });
  }

  setMarkerClusterer(markerClusterer) {
    this.setState({ markerClusterer });
  }

  renderCluster(cluster) {
    const {
      selectedPlace,
    } = this.state;
    const { google } = this.props;
    const {
      clusterIcon,
      activeClusterIcon,
    } = getIcons(this.props.google);


    let icon = clusterIcon;
    if (selectedPlace) {
      const isActiveCluster = cluster.markers.find(
        marker => marker.position.lat() === selectedPlace.latitude
        && marker.position.lng() === selectedPlace.longitude,
      );
      if (isActiveCluster) {
        icon = activeClusterIcon;
      }
    }

    return new google.maps.Marker({
      label: {
        text: String(cluster.count), color: "white", fontSize: "14px", fontWeight: "700",
      },
      icon,
      position: cluster.position,
      zIndex: Number(google.maps.Marker.MAX_ZINDEX) + cluster.count,
    });
  }

  render() {
    const {
      coordinates,
      vendingMachines,
      selectedPlace,
      activeMarker,
      markerClusterer,
    } = this.state;
    const { google, fullscreen, setFullscreen } = this.props;

    const onCenter = (callback) => {
      const success = (position) => {
        this.setState({
          coordinates: {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          },
        });
        callback(position);
      };
      const error = (e) => {
        console.error(e);
      };

      navigator.geolocation.getCurrentPosition(success, error, options);
    };
    return (
      <Map
        google={google}
        initialCoordinates={coordinates}
        vendingMachines={vendingMachines}
        onCenter={onCenter}
        fullscreen={fullscreen}
        setFullscreen={setFullscreen}
        selectedPlace={selectedPlace}
        activeMarker={activeMarker}
        markerClusterer={markerClusterer}
        markers={this.state.markers}
        setSelectedPlace={this.setSelectedPlace}
        setActiveMarker={this.setActiveMarker}
        setMarkers={this.setMarkers}
        setMarkerClusterer={this.setMarkerClusterer}
        renderCluster={this.renderCluster}
      />
    );
  }
}

export default GoogleApiWrapper({
  apiKey: googleMapKey,
  version: "3.42.9",
  region: "JP",
  language: "ja",
})(MapController);
