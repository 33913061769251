import React from "react";
import styled from "styled-components";

const Link = styled.a`
    cursor: pointer;
    justify-content: center;
    padding-bottom: .5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: .5rem;
    text-align: center;
    white-space: nowrap;
    display: inline-flex;
    font-size: 1rem;
    height: 2.5rem;
    user-select: none;

    font-family: inherit;
    box-sizing: border-box;
    border: 1px solid
    border-width: 1px;
    font-weight: 800;

    border-radius: 20px;
    border-style: solid;

    width: 264px;

    display: flex;
    align-items: center;
    justify-content: center;

    border-color: ${props => (props.primary ? "#03AFDB" : "#03AFDB")};
    background-color: ${props => (props.primary ? "#03AFDB" : "#FFFFFF")};
    color: ${props => (props.primary ? "#FFFFFF" : "#03AFDB")};

    &:hover:enabled {
        background-color: ${props => (props.primary ? "#03AFDB" : "#FFFFFF")};
        border-color: ${props => (props.primary ? "#03AFDB" : "#03AFDB")};
        color: ${props => (props.primary ? "#FFFFFF" : "#03AFDB")};
    }
    &:disabled{
        background-color: #fff;
        border-color: #dbdbdb;
        box-shadow: none;
        opacity: .5;
        cursor: not-allowed;
    }

    text-decoration: none;
    border-width: 1px;
`;

const ButtonComponent = props => (
  <Link type="button" {...props}>
    {props.children}
  </Link>
);


export default ButtonComponent;
